import classNames from 'classnames';
export const LandingHero = ({ title, description, action, image, imageStyle, extraContent, inverted, background = 'default' }) => {

    const classes = classNames('landing-hero', {
        'landing-hero--inverted': inverted,
        [`landing-hero--${background}`]: background
    });

    return (
        <div className={classes}>
            <div className="landing-hero__content">
                <span className="landing-hero__content-title">{title}</span>
                <p className="landing-hero__content-description" dangerouslySetInnerHTML={{ __html: description }}></p>
                {action}
                {extraContent} 
            </div>
            {image && <div className="landing-hero__image">
                <img src={image} alt={description} style={imageStyle} />
            </div>}
        </div>
    )
};