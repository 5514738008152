import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import LoginPage from './components/pages/login';
import ForgotPage from './components/pages/forgot';
import ProfilePage from './components/pages/profile';
import MissionsPage from './components/pages/missions';
import hasPermission from './middleware/utils/permissions';
import ValidatorPage from './components/pages/validator';
import EpaSchedulePage from './components/pages/epa-schedule';
import RegisterCandidate from './components/pages/register-candidate';
import ChallengePage from './components/pages/challenge';
import InductionDay from './components/pages/induction-day';
import FilterSchedule from './components/pages/filter-schedule';
import { UserContext } from './middleware/providers/user-context';
import { TabsProvider } from './middleware/providers/tabs-context';
import ValidatorInfoPage from './components/pages/validator-info-page';
import Builder from './components/pages/builder';
import connectToSocket from './middleware/socket/socket-client';
import { EpaLinkPage } from './components/pages/epa-link';
import { LandingPage } from './components/pages/landing';

function App() {
  const { user, login, setMedalModal, setEventEpa, setChangeRoleContext } = useContext(UserContext);

  const validSession = (page) => {
    const session = document.cookie.split(';').find(cookie => cookie.includes('session-id')) ? true : false;

    if (hasPermission(page, session)) {
      if (session && page !== 'login') {
        return true;
      }
    }
    return session ? true : false;
  };

  const Test = (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userData = JSON.parce(searchParams.get('userData'));
    document.cookie = `session-id=${userData.accessToken}|${userData.id}`;

    return (
      <Navigate to={`/profile`} />
    )
  };

  useEffect(() => {
    if (!user && validSession('profile')) {
      login();
    }

    if (user) {
      const socketCreatedInstance = connectToSocket(setMedalModal, user?.createByHost, null);
      const socketUserInstance = connectToSocket(setMedalModal, null, setChangeRoleContext);
      const socketEpaInstance = connectToSocket(setEventEpa, 'epa', null);

      return () => {
        socketCreatedInstance?.disconnect();
        socketUserInstance?.disconnect();
        socketEpaInstance?.disconnect();
      }
    }
  }, [user]);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={!validSession('login') ? <LoginPage /> : <Navigate to='/missions' />} />
        <Route exact path="/forgot" element={<ForgotPage />} />
        <Route exact path="/forgot/:token" element={<ForgotPage />} />
        <Route exact path="/profile" element={validSession('profile') ? <ProfilePage /> : <Navigate to='/' />} />
        <Route exact path="/missions" element={validSession('missions') ? <TabsProvider ><MissionsPage /></TabsProvider> : <Navigate to='/' />} />
        <Route exact path="/missions/:update" element={validSession('missions') ? <TabsProvider ><MissionsPage /></TabsProvider> : <Navigate to='/' />} />
        <Route exact path="/induction-day/:challenge" element={<InductionDay />} />
        <Route exact path="/induction-day/:challenge/:idChallenge" element={<InductionDay />} />
        <Route exact path="/challenge/:challengeName/:idChallenge" element={validSession('challenge') ? <TabsProvider ><ChallengePage /></TabsProvider> : <Navigate to='/' />} />
        <Route exact path="/validator/:token" element={<ValidatorPage />} />
        <Route exact path="/validator-info/:index/:idChallenge/:idUser" element={<TabsProvider ><ValidatorInfoPage /></TabsProvider>} />
        <Route exact path="/progress" element={<TabsProvider ><ValidatorInfoPage /></TabsProvider>} />
        <Route exact path="/register-reference/:index/:idChallenge" element={<TabsProvider ><ValidatorInfoPage /></TabsProvider>} />
        <Route path="/schedule/:idHost" element={<EpaSchedulePage />} />
        <Route path="/filter-schedule/:idChallenge" element={<FilterSchedule />} />
        <Route path="/federated-login" element={<Test />} />
        <Route path="/register-candidate" element={<RegisterCandidate />} />
        <Route path="/builder/:page" element={<Builder />} />
        <Route exact path="/epa-link" element={<EpaLinkPage />} />
        <Route exact path="/landing" element={<LandingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
