import { LandingFooterLayout } from "../../templates/landing-footer-layout";
import LandingFooterLogo from "../../../resources/images/landing-footer-logo.png";

export const LandingFooter = () => {
    return (
        <LandingFooterLayout>
            <nav className="landing-footer-nav-info">
                <img src={LandingFooterLogo} alt="Logo" className="landing-footer-nav-info-logo" />
                <ul className="landing-footer-nav-info-list">
                    <li className="landing-footer-nav-info-list-item">
                        <a href="mailto:info@universoacdc.com" className="landing-footer-nav-info-list-item-link"><span className="icon-mail"></span> info@universoacdc.com</a>
                    </li>
                    <li className="landing-footer-nav-info-list-item">
                        <a href="tel:+573152437927" className="landing-footer-nav-info-list-item-link"><span className="icon-phone"></span> +57 3152437927</a>
                    </li>
                    <li className="landing-footer-nav-info-list-item">
                        <a href="https://www.google.com/maps/place/Cra.+75+%239-87,+Comuna+17,+Cali,+Valle+del+Cauca/@3.3905597,-76.5426189,21z/data=!4m6!3m5!1s0x8e30a15be44413ff:0x9c824c40f1c61d60!8m2!3d3.3905363!4d-76.5425575!16s%2Fg%2F11h4xgkn65?hl=es&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D" className="landing-footer-nav-info-list-item-link" target="_blank" rel="noopener noreferrer"><span className="icon-location"></span> Cra 75 nro 9-87, Cali</a>
                    </li>
                </ul>
            </nav>
            <nav className="landing-footer-nav-about-us">
                <span className="landing-footer-nav-about-us-title"><span className="square"></span>Nosotros</span>
                <ul className="landing-footer-nav-about-us--list">
                    <li className="landing-footer-nav-about-us--list--item">
                        <a href="#about-us" className="landing-footer-nav-about-us--list--item--link">¿Quiénes somos?</a>
                    </li>
                    <li className="landing-footer-nav-about-us--list--item">
                        <a href="#downloads" className="landing-footer-nav-about-us--list--item--link">Descargas</a>
                    </li>
                    <li className="landing-footer-nav-about-us--list--item">
                        <a href="#tutorials" className="landing-footer-nav-about-us--list--item--link">Tutoriales</a>
                    </li>
                    <li className="landing-footer-nav-about-us--list--item">
                        <a href="#merch" className="landing-footer-nav-about-us--list--item--link">Merch</a>
                    </li>
                </ul>
            </nav>
            <nav className="landing-footer-nav-support">
                <span className="landing-footer-nav-support-title"><span className="square"></span>Soporte</span>
                <ul className="landing-footer-nav-support--list">
                    <li className="landing-footer-nav-support--list--item">
                        <a href="#contact" className="landing-footer-nav-support--list--item--link">Contáctanos</a>
                    </li>
                    <li className="landing-footer-nav-support--list--item">
                        <a href="#feedback" className="landing-footer-nav-support--list--item--link">PQRs</a>
                    </li>
                    <li className="landing-footer-nav-support--list--item">
                        <a href="#faq" className="landing-footer-nav-support--list--item--link">Políticas de privacidad</a>
                    </li>
                </ul>
            </nav>
            <nav className="landing-footer-nav-members">
                <span className="landing-footer-nav-members-title"><span className="square"></span>Miembros</span>
                <ul className="landing-footer-nav-members--list">
                    <li className="landing-footer-nav-members--list--item">
                        <a href="#actions" className="landing-footer-nav-members--list--item--link">Acciones</a>
                    </li>
                    <li className="landing-footer-nav-members--list--item">
                        <a href="#founders" className="landing-footer-nav-members--list--item--link">Fundadores</a>
                    </li>
                    <li className="landing-footer-nav-members--list--item">
                        <a href="#labs" className="landing-footer-nav-members--list--item--link">Laboratorios Territorialistas</a>
                    </li>
                </ul>
            </nav>
            <nav className="landing-footer-nav-social-media">
                <span className="landing-footer-nav-social-media-title"><span className="square"></span>Redes Sociales</span>
                <ul className="landing-footer-nav-social-media--list--icons">
                    <li className="landing-footer-nav-social-media--list--icons--item">
                        <a href="https://www.instagram.com/universoacdc/" target="_blank" rel="noopener noreferrer" className="icon-instagram"></a>
                    </li>
                    <li className="landing-footer-nav-social-media--list--icons--item">
                        <a href="https://www.linkedin.com/company/universoacdc/" target="_blank" rel="noopener noreferrer" className="icon-linkedin"></a>
                    </li>
                    <li className="landing-footer-nav-social-media--list--icons--item">
                        <a href="https://twitter.com/universoacdc" target="_blank" rel="noopener noreferrer" className="icon-twitter"></a>
                    </li>
                    <li className="landing-footer-nav-social-media--list--icons--item">
                        <a href="https://www.youtube.com/@universoacdc" target="_blank" rel="noopener noreferrer" className="icon-youtube"></a>
                    </li>
                    <li className="landing-footer-nav-social-media--list--icons--item">
                        <a href="https://www.facebook.com/universoacdc" target="_blank" rel="noopener noreferrer" className="icon-facebook"></a>
                    </li>
                </ul>
            </nav>
            <ul className="landing-footer-nav--list">
                <li className="landing-footer-nav--list--item">
                    ©2024 Ecosistema ACDC | Todos los derechos reservados
                </li>
            </ul>
        </LandingFooterLayout>
    )
};